import { initializeApp } from 'firebase/app';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Cloud Messaging and get a reference to the service
const messaging = getMessaging(firebaseApp);

// Initialize Cloud Firestore and get a reference to the service
const firestoreDb = getFirestore(firebaseApp);

// https://github.com/firebase/firebase-js-sdk/issues/7575#issuecomment-1711705543
export const getUserNotificationsToken = async (maxRetries = 6) => {
    let retries = 0;
    let error = null;
    while (retries < maxRetries) {
        try {
            return await getToken(messaging, {
                vapidKey: import.meta.env
                    .VITE_FIREBASE_CLOUD_MESSAGING_VAPID_KEY,
            });
        } catch (e) {
            retries++;
            console.error(
                `Error fetching token. Retrying (${retries}/${maxRetries})`,
                e,
            );
            await new Promise((resolve) => setTimeout(resolve, 1000));
            error = e;
        }
    }

    throw new Error(error);
};

export const onMessageHandler = (handler) => onMessage(messaging, handler);

export const onSnapshotHandler = (collectionName, documentId, handler) => {
    const docRef = doc(firestoreDb, collectionName, documentId);
    return onSnapshot(docRef, handler);
};
